let body = document.querySelector('body');
let burger = document.querySelector('.js-burger');
let btnClose = document.querySelector('.js-burger-closed');
let mobilemenu = document.querySelector('.js-menu');

burger.onclick = function() {
	mobilemenu.classList.toggle('is-active');
	body.classList.toggle('is-overflow');
}

btnClose.onclick = function() {
	mobilemenu.classList.toggle('is-active');
	body.classList.toggle('is-overflow');
}
